
/* For mobile and all others */
body {
  background: fixed white url(../img/css_elements/ppat.gif) repeat top left;
}

*:focus {
  outline: none;
}

header {
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  font-size: 55px;
  font-weight: bold;
  padding: 0px;
  letter-spacing: -1px;
  border-bottom: 2px solid black;
  background-color: #ffffff;
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
  box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
}

header img {
  width: 90%;
}

#content-wrapper {
  background: rgb(255, 255, 255);
  font: normal 13px/20px georgia, serif;
  margin: auto;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  -webkit-box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
  box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
}

#content {
  padding: 10px;
  padding-bottom: 50px;
}

#content img {
  -webkit-box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
  box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

a:link, a:visited {
  color: rgb(94, 94, 94);
}

a:hover, a:active {
  color: black;
}

.biography,
.contact,
.galleries,
.gallery,
.home,
.legal,
.photo,
.pricing,
.purchase,
.techniques {
  -webkit-animation: fadein 1.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.2s; /* Firefox < 16 */
  -ms-animation: fadein 1.2s; /* Internet Explorer */
  -o-animation: fadein 1.2s; /* Opera < 12.1 */
  animation: fadein 1.2s;
}


#navigation a {
  font: 12px;
  font-weight: bold;
  width: 100%;
  letter-spacing: 2px;
  margin: auto;
  z-index: 100;
  left: 0;
  top: 0;
  padding: 8px 9px;
  line-height: 32px;
  text-decoration: none;
  outline: none;
  text-align: center;
  color: rgb(150, 150, 150);

  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

#navigation a:active {
  background-color: transparent !important;
}

#navigation a.active {
  color: black;
  text-decoration: underline;
}

#navigation a:hover {
  color: black;
}

.home img {
  width: 85%;
  max-width: 900px;
}

.galleries img,
.gallery img,
.purchase img {
  width:9.5em;
}

#pricing table.pricing-table,
.techniques table {
  margin: auto;
  padding: 20px;
}

#pricing table.pricing-table td,
.techniques table td {
  width: 120px;
  padding-left: 10px;
  padding-right: 10px;
}

.gallery-container {
  display: inline-grid;
  grid-template-columns: [full-start] 1fr [full-end];
  grid-row-gap: 50px;
  margin-top:10px;
  margin-bottom: 10px;
}

.imagewell {
  margin: auto;
  width: auto;
}

form,
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

form {
  margin-top: 30px;
}

.contact {
  text-align: center !important;
}

#contact-input{
  width: 70%;
  padding:10px;
  font:normal 13px/20px georgia, serif;
  /* text-align: center !important; */
}

#contact-form {
  text-align: center;
  /* width: 80%; */
}

.form-group {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
}
.form-group p {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

input {
  margin-bottom: 15px;
}

.submit-button {
  background-color: rgb(238, 238, 238);
  padding: 9px 10px;
  border: none;
  cursor: pointer;
  display: inline-block;
  border-radius: 3px;
  font: italic 13px/20px georgia, serif;
  font-size: 16px;
  transition: all .2s ease-in-out;
}

#addToCart, #checkout, #emptyCart{
  background-color: rgb(238, 238, 238);
  padding: 9px 10px;
  border: none;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  margin:5px;
  width: auto;
}

select {
  text-align: center;
  border: none;
  background-color: rgb(238, 238, 238);
  border-radius: 3px;
  transition: all .2s ease-in-out;
  margin: 2px;
  width: auto;
}

.submit-button:hover, #addToCart:hover, #goToCart:hover, #checkout:hover, #emptyCart:hover, select:hover{
  background-color: rgb(211, 211, 211);
  border-radius: 3px;
  cursor: pointer;
}

input.button {
  padding: 0 20px;
}

label {
  font-weight: bold;
}

/* select {
  height: 16px;
} */

textarea {
  height: 175px;
  margin: 0 0 15px 0;
  padding: 0;
  width: 380px;
}

h2 {
  font-size: 35px;
  font-style: italic;
  font-weight: normal;
  letter-spacing: -1px;
  line-height: 35px;
  margin-bottom: 30px;
}

h3 {
  clear: both;
  font-size: 140%;
  font-weight: bold;
  text-align: justify;
}

h4 {
  color: #666;
  padding-left: 10px;
  font-size: 125%;
  font-style: italic;
  font-weight: normal;
}

footer {
  background-color: #f3f3f3;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  clear: both;
  color: #aaa;
  font-size: 11px;
  line-height: 13px;
  padding: 5px 0;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.biography p,
.legal p,
.techniques p,
.pricing p,
.techniques ul,
.contact form {
  text-align: justify;
}

.popup {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  bottom:0;
  left:0;
  margin:auto;
  position:fixed;
  right:0;
  top:0;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 1;
  background-color: white;
  padding-bottom: 100px;
}

.popup img, .popup a {
  height: auto;
  width: auto;
  max-width: 70%;
  max-height: 70%;
}

.popup h2 {
  padding-top: 20px;
}

.techniques .popup {
    position: unset;
    top: unset;
    left: unset;
  
    -ms-transform: unset;
    transform: unset;
  
    padding: unset;
    width: unset;
    z-index: unset;
    background-color: unset;
    filter: unset !important;

    height: 70%;
    width: 90%;
    bottom:0;
    left:0;
    margin:auto;
    padding: 30px;
    position:fixed;
    right:0;
    top:0;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: 1;
    background-color: white;

    overflow: scroll;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:20px;
  }

.close-button img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;

  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;

  filter: none !important;
}

.bg-blur {
  background: rgba(209, 209, 209, 0.712);

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);

  cursor: default;
}

.hidden {
  height: 0;
}

div.gallery div.gallery-container div.imagewell div div.popup, .galleries a, .gallery a, .purchase a{
  width: 700px;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
}

div.imagewell div div.popup a:link, div.imagewell div div.popup a:visited,
div.imagewell div div.popup a:hover, div.imagewell div div.popup a:active,
.galleries a, .gallery a, .purchase a {
  text-decoration: none;
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}

div.imagewell div div.popup h2 {
  margin-top: 0px;
}

select {
  
}

/* These are copy and pasted from the old site */

.even {
  background-color: rgb(226, 226, 226);
}
.odd {
  background-color: rgb(245, 245, 245);
}

div.right,
div.left {
  width: 225px;
}

div.leftw,
div.rightw {
  height: 225px;
  width: 100%;
}

div.right,
div.rightw,
div.left,
div.leftw {
  margin: auto;
  grid-column: 3;
}

#img-1 {
  grid-row: 1;
}

#img-2 {
  grid-row: 2;
}

div.technique-0 {
  grid-column: 1/4;
}

div.technique-1 {
  grid-column: 1/3;
  grid-row: 1;
}

div.technique-2 {
  grid-column: 1/3;
  grid-row: 2;
}

div.technique-3 {
  grid-column: 1/3;
  grid-row: 3;
}

div.technique-4 {
  grid-column: 1/4;
  grid-row: 4;
}

div.technique-5 {
  grid-column: 1/3;
  grid-row: 4;
}

div.technique-6 {
  grid-column: 1/3;
  grid-row: 5;
}

div.assembly-1 {
  grid-column: 1;
  grid-row: 5;
}

div.assembly-2 {
  grid-column: 2;
  grid-row: 5;
}

div.assembly-3 {
  grid-column: 3;
  grid-row: 5;
}

/* For Desktop */
@media only screen and (min-width: 900px) and (min-height:800px){
  #content {
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
  }

  #content-wrapper{
    width: 900px;
  }
  
  .hidden, .hidden a {
    height: 0px;
    width: 0px;
  }

  .hidden .popup {
    position: fixed;
    min-height: 500px;
  }

  .popup {
    position: absolute;
    top: 110px;
    left: 50%;
  
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  
    padding: 30px;
    width: 900px;
    z-index: 1;
    background-color: white;
    filter: none !important;
  
    -webkit-box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
    box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);


    max-width: unset;
    max-height: unset;
    bottom:unset;
    margin:unset;
    right:unset;
    -o-object-fit: unset;
    object-fit: unset;
  }
  
  .popup img { 
    width: 70%;
  }

  .popup h2 {
    padding-top:unset;
  }

  .techniques .popup {
    height: unset;
    bottom:unset;
    margin:unset;
    right:unset;
    -o-object-fit: unset;
    object-fit: unset;
    position: absolute;
    top: 110px;
    left: 50%;
  
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  
    padding: 30px;
    width: 900px;
    z-index: 1;
    background-color: white;
    filter: none !important;
  
    -webkit-box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);
    box-shadow: 3px 3px 6px 0 rgba(90, 90, 90, 0.7);

    overflow: unset;
  }

  .close-button img {
    width: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
  
    cursor: pointer;
  
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
  
    filter: none !important;
  }
  
  .bg-blur {
    background: rgba(209, 209, 209, 0.712);
  
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  
    cursor: default;
  }
}

/* For Tablets */
@media only screen and (min-width: 700px) {
  .gallery-container {
    grid-template-columns: [full-start] 1fr [content-start] 2fr [content-end] 1fr [full-end];
    justify-self: center;
  }
}

@media only screen and (min-width: 677px) {
  header img{
    width: unset;
  }
}

@media only screen and (min-width: 600px) {
  header {
    padding-top: 25px;
    padding-bottom: 10px;
  }
}